<template>
	<div class=" ">
		<el-card>
			<!-- <div slot="header" class="card_title"> </div> -->
			<div class="add_main hidden_scroll">
				<div class="sub_content">
					<div class="">
						<el-form
							label-position="right"
							label-width="120px"
							:model="formData"
							:rules="rule"
							ref="formData"
						>
							<div style="height: 40px"></div>
							<el-form-item label="中文标题" prop="title">
								<el-input
									v-model="formData.title"
									:disabled="$route.query.type == 'detail'"
								>
								</el-input>
							</el-form-item>

							<el-form-item label="中文摘要" prop="illustrate">
								<el-input
									v-model="formData.illustrate"
									:disabled="$route.query.type == 'detail'"
								>
								</el-input>
							</el-form-item>
							<el-form-item
								label=""
								prop="content_en"
								v-if="formData.language == 2"
							>
								<el-upload
									id="upimg"
									v-show="false"
									class="upload-demo"
									:disabled="$route.query.type == 'detail'"
									action="/upload"
									:before-upload="beforeUpload"
									multiple
								>
									<el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
								<quill-editor
									:options="editorOption"
									class="ql-editor"
									ref="QuillEditor"
									:disabled="$route.query.type == 'detail'"
									v-model="formData.content_en"
									@blur="onEditorBlur($event)"
									@focus="onEditorFocus($event)"
									@ready="onEditorReady($event)"
									@change="onEditorChange($event)"
								/>
							</el-form-item>
							<el-form-item label="" prop="content" v-else>
								<el-upload
									id="upimg"
									v-show="false"
									class="upload-demo"
									:disabled="$route.query.type == 'detail'"
									action="/upload"
									:before-upload="beforeUpload"
									multiple
								>
									<el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
								<quill-editor
									:options="editorOption"
									class="ql-editor"
									ref="QuillEditor"
									:disabled="$route.query.type == 'detail'"
									v-model="formData.content"
									@blur="onEditorBlur($event)"
									@focus="onEditorFocus($event)"
									@ready="onEditorReady($event)"
									@change="onEditorChange($event)"
								/>
							</el-form-item>

							<span class="font_weight_bold">作者信息</span>
							<el-form-item label="账号类型" prop="userType">
								<el-select
									v-model="userType"
									placeholder=""
									:disabled="$route.query.type == 'detail'"
									@change="handleUserTypeChange"
								>
									<el-option label="内部员工" value="2"> </el-option>
									<el-option label="外部账号" value="1"> </el-option>
								</el-select>
							</el-form-item>
							<el-form-item
								label="作者名称"
								class="margin_top_20"
								prop="author_id"
							>
								<el-select
									v-model="formData.author_id"
									placeholder="请选择作者"
									:disabled="$route.query.type == 'detail'"
									collapse-tags
									clearable
									filterable
								>
									<el-option
										:label="item.nickname"
										:value="item.id"
										v-for="(item, index) in userSelList"
										:key="index"
									>
									</el-option>
								</el-select>
							</el-form-item>
							<div class="margin_top_40 font_weight_bold">文章信息</div>
							<el-form-item label="文章类型" prop="type">
								<el-radio
									v-model="formData.type"
									label="1"
									:disabled="$route.query.type == 'detail'"
									@change="handletypeChange"
								>
									文章
								</el-radio>
								<el-radio
									v-model="formData.type"
									label="2"
									:disabled="$route.query.type == 'detail'"
									@change="handletypeChange"
								>
									快讯
								</el-radio>
								<el-radio
									v-model="formData.type"
									label="5"
									:disabled="$route.query.type == 'detail'"
									@change="handletypeChange"
								>
									专栏
								</el-radio>
								<el-radio
									v-model="formData.type"
									label="7"
									:disabled="$route.query.type == 'detail'"
									@change="handletypeChange"
								>
									活动
								</el-radio>
							</el-form-item>
							<el-form-item label="资讯分类" prop="category_id">
								<!-- <el-select
									v-model="formData.category_id"
									placeholder="请选择资讯分类"
									:disabled="$route.query.type == 'detail'"
								>
									<el-option
										:label="item.name"
										:value="item.id"
										v-for="(item, index) in categoryList_show"
										:key="index"
									>
									</el-option>
								</el-select> -->

								<el-radio
									v-model="formData.category_id"
									:label="item.id"
									:disabled="$route.query.type == 'detail'"
									v-for="(item, index) in categoryList_show"
									:key="index"
								>
									{{ item.name }}
								</el-radio>
							</el-form-item>
							<el-form-item label="专区">
								<el-cascader
									v-model="formData.special_id"
									:options="specialList"
									:disabled="$route.query.type == 'detail'"
									clearable
									filterable
								></el-cascader>
							</el-form-item>

							<el-form-item label="选择专题">
								<el-checkbox-group v-model="ticketArray">
									<el-checkbox
										v-for="(item, index) in labelList"
										:label="item.id"
										:key="index"
										>{{ item.name }}</el-checkbox
									>
								</el-checkbox-group>
							</el-form-item>
							<el-form-item label="原文链接" prop="url">
								<el-input
									v-model="formData.url"
									:disabled="$route.query.type == 'detail'"
								></el-input>
							</el-form-item>

							<el-form-item label="封面图片" prop="pic">
								<p style="color: red; font-size: 18px">（尺寸要求16:9）</p>
								<div class="upload_img_div" v-if="formData.pic">
									<img
										style="width: 80%"
										:src="formData.pic"
										@click="uploadPicture(formData.pic)"
									/>
									<div class="upload_img_del">
										<img
											@click="removePicture"
											src="../../../assets/images/upload_img_del.png"
										/>
									</div>
								</div>

								<div v-else>
									<div class="el-upload__text">
										<img
											style="width: 100px"
											src="../../../assets/images/uploadImg.png"
											alt=""
											@click="uploadPicture(formData.pic)"
										/>
										<br /><em>点击加号上传</em></div
									>
								</div>
								<!-- 剪裁组件弹窗 -->
								<el-dialog
									title=""
									:visible.sync="cropperModel"
									width="1300px"
									center
									:append-to-body="true"
								>
									<template v-if="cropperModel">
										<cropper-image-new
											:Name="cropperName"
											:type="scale"
											@uploadImgSuccess="handleUploadSuccess"
											ref="child"
										>
										</cropper-image-new
									></template>
								</el-dialog>
							</el-form-item>

							<el-form-item label="是否置顶">
								<el-radio
									v-model="formData.is_top"
									label="1"
									:disabled="$route.query.type == 'detail'"
									>是</el-radio
								>
								<el-radio
									v-model="formData.is_top"
									label="0"
									:disabled="$route.query.type == 'detail'"
									>否</el-radio
								>
							</el-form-item>

							<el-form-item label="允许推送tg">
								<el-radio
									v-model="formData.allow_tgpush"
									label="0"
									:disabled="$route.query.type == 'detail'"
									>禁止</el-radio
								>
								<el-radio
									v-model="formData.allow_tgpush"
									label="1"
									:disabled="$route.query.type == 'detail'"
									>允许</el-radio
								>
							</el-form-item>
							<el-form-item label="发布时间" prop="release_time">
								<el-date-picker
									v-model="formData.release_time"
									:disabled="$route.query.type == 'detail'"
									value-format="yyyy-MM-dd HH:mm:ss"
									format="yyyy-MM-dd HH:mm:ss"
									type="datetime"
									placeholder=""
								>
								</el-date-picker>
							</el-form-item>

							<el-form-item>
								<div class="flex justify_content_c align_items_c">
									<el-button
										type="primary"
										class="custom_btn_width_100"
										@click="submit('formData', 1)"
										v-if="$route.query.type != 'detail'"
									>
										确定
									</el-button>
									<el-button
										class="custom_btn_width_100"
										@click="submit('formData', 0)"
										v-if="$route.query.type != 'detail'"
									>
										保存草稿
									</el-button>
									<el-button class="custom_btn_width_100" @click="back">
										取消
									</el-button>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</el-card>
		<div v-if="loading" class="loading-overlay">
			<!-- loading遮罩内容 -->
			<div class="spinner"></div>
		</div>
	</div>
</template>

<script>
import { myMixin } from "@/utils/mixin.js";
import { getUserId } from "@/utils/auth";

import {
	getCategoryList,
	addInformation,
	getLabelList,
	getInformationDetail,
	specialList,
} from "@/api/article";
import { uploadImage, getUsersList } from "@/api/user";
import { validateNull } from "@/utils/validate";

import Quill from "quill";
import VueQuillEditor from "vue-quill-editor";
import imageResize from "quill-image-resize-module"; // 图片缩放组件引用
Quill.register("modules/imageResize", imageResize);

import "quill/dist/quill.core.css"; // import stylesheet for quill editor
import "quill/dist/quill.core.js"; // import stylesheet for quill editor
import "quill/dist/quill.snow.css"; // import stylesheet for snow theme (optional)
import "quill/dist/quill.bubble.css"; // import stylesheet for bubble theme (optional)
import { number } from "echarts";

// 自定义字号的大小
var sizes = [false, "16px", "18px", "20px", "22px", "26px", "28px", "30px"];
var Size = Quill.import("attributors/style/size");
Size.whitelist = sizes;
Quill.register(Size, true);

const toolbarOptions = [
	["bold", "italic", "underline", "strike"], // toggled buttons
	["blockquote", "code-block"],

	[{ header: 1 }, { header: 2 }], // custom button values
	[{ list: "ordered" }, { list: "bullet" }],
	[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
	[{ direction: "rtl" }], // text direction

	[{ size: Size.whitelist }], // 配置字号
	[{ header: [1, 2, 3, 4, 5, 6, false] }],

	[{ color: [] }, { background: [] }], // dropdown with defaults from theme
	// [{ font: [] }],
	[{ align: [] }],
	["link", "image", "video"],
	["clean"], // remove formatting button
];

export default {
	mixins: [myMixin],
	name: "",
	components: {
		VueQuillEditor,
	},
	data() {
		return {
			props: { multiple: false },
			rule: {
				category_id: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				author_id: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				title: [{ validator: validateNull, trigger: "blur", required: true }],
				illustrate: [
					{ validator: validateNull, trigger: "blur", required: true },
				],

				language: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				pic: [{ validator: validateNull, trigger: "change", required: true }],
				content: [{ validator: validateNull, trigger: "blur", required: true }],

				status: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				type: [{ validator: validateNull, trigger: "change", required: true }],

				release_time: [
					{ validator: validateNull, trigger: "change", required: true },
				],
			},
			editorOption: {
				theme: "snow",
				placeholder: "输入内容...",
				imageResize: true, // 开启图片调整大小功能
				modules: {
					imageResize: {
						//放大缩小
						displayStyles: {
							backgroundColor: "black",
							border: "none",
							color: "white",
						},
						modules: ["Resize", "DisplaySize", "Toolbar"],
					},
					toolbar: {
						container: toolbarOptions, // 工具栏
						handlers: {
							image: function (value) {
								if (value) {
									// 调用element的图片上传组件
									// （这里是用的原生js的选择dom方法）
									document.querySelector("#upimg button").click();
									// this.uploadPicture("");
								} else {
									this.quill.format("image", false);
								}
							},
						},
					},
				},
			},
			loading: false,
			value: "",
			content: "",
			content_en: "",
			tmp_special_id: "",
			ticketArray: [],
			userType: "2",
			formData: {
				author_id: "",
				category_id: "",
				special_id: "",
				label_id: 0,
				ticket_id: [],
				title: "",
				title_en: "",
				illustrate: "",
				illustrate_en: "",
				url: "",
				source_id: "",
				language: "1",
				pic: "",
				content: "",
				content_en: "",
				status: 1,
				type: "",
				prohibit_tran: 0,
				allow_tgpush: 1,
				is_home: "",
				is_recommend: "0",
				is_top: "",
				release_time: "",
				score: "",
				is_push: "",
			},
			// 专题id
			specialId: "",
			// 资讯分类
			categoryList: [],
			categoryList_show: [],
			// 用户列表
			userList: [],
			userSelList: [],
			// 标签列表
			labelList: [],
			// 资讯详情
			formationDetail: {},
			// 专题数据
			specialList: [],
			//参数
			cropperModel: false,
			cropperName: "",
			scale: "0",
		};
	},
	watch: {
		"$route.query.id": [
			function handler1() {
				if (!this.$route.query.id) {
					this.clearFormAdd();
				} else {
					this.getInformationDetail();
				}
			},
		],
	},
	created() {
		this.getCategoryListFun();
		this.getUsersListFun();
		this.getLabelListFun();
		this.getspecialList();
		if (!this.$route.query.id) {
			this.clearFormAdd();
		} else {
			this.getInformationDetail();
		}
	},
	methods: {
		onEditorBlur(quill) {
			let e = document.querySelector(".ql-tooltip,.ql-editing,.ql-flip");
			if (e) {
				let left = e.style.left;
				let top = e.style.top;
				if (left.indexOf("-") === 0) {
					e.style.left = 10 + "px";
				}
				if (top.indexOf("-") === 0) {
					e.style.top = 60 + "px";
				}
			}
		},
		removePicture() {
			this.formData.pic = "";
		},
		//封面设置
		uploadPicture(name) {
			if (this.$route.query.type == "detail") {
				// 详情不支持编辑图片
			} else {
				this.cropperName = name;
				this.type = "1";
				this.cropperModel = true;
			}
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			this.cropperModel = false;
			if (
				this.cropperName != null &&
				this.cropperName.indexOf("blob:http") != -1
			) {
				this.handleSuccess(data);
			} else {
				this.formData.pic = data.url;
			}
		},
		// 资讯分类列表
		getCategoryListFun() {
			const that = this;
			getCategoryList({ size: 100 })
				.then((res) => {
					if (res.code == 200) {
						that.categoryList = res.data.data;
						that.categoryList.forEach((item) => {
							item.id = item.id.toString();
						});
					} else {
						that.categoryList = [];
					}
					that.getDefaultCate(that.formData.type);
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		// 上传切图前调用
		beforeUpload(file) {
			this.cropperName = URL.createObjectURL(file);
			this.type = "0";
			this.cropperModel = true;
			return false;
		},
		handleSuccess(data) {
			// 获取富文本组件实例
			let quill = this.$refs.QuillEditor.quill;
			if (data) {
				// 如果上传成功
				// 获取光标所在位置
				// let length = quill.getSelection().index;
				let length = 0;
				if (quill.getSelection() && quill.getSelection().index) {
					length = quill.getSelection().index;
				} else {
					length = 0;
				}
				// 插入图片，res为服务器返回的图片链接地址
				quill.insertEmbed(length, "image", data.url);
				// 调整光标到最后
				quill.setSelection(length + 1);
			} else {
				// 提示信息
				this.$message.error("图片插入失败");
			}
		},

		// 获取用户列表
		getUsersListFun() {
			const that = this;
			getUsersList({ type: 1, size: 900 })
				.then((res) => {
					if (res.code == 200) {
						that.userList = res.data.list;
						that.getUserType();
					} else {
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		// 获取标签列表
		getLabelListFun() {
			const that = this;
			getLabelList({})
				.then((res) => {
					if (res.code == 200) {
						that.labelList = res.data.data;
						// if (this.$route.query.type != "detail") {
						// 	that.formData.ticket_id = that.getLstInteger("9,13,27,29,44,46,");
						// }
					} else {
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		// 新增资讯提交
		submit(formName, status) {
			let ticket_id = "";

			if (this.ticketArray) {
				console.log("this.ticket_id:", this.ticketArray);

				if (this.ticketArray.includes(",")) {
					ticket_id = this.ticketArray;
				} else {
					this.ticketArray.forEach((item) => {
						ticket_id += item + ",";
					});
				}
			}
			let formData = this.formData;
			formData.ticket_id = ticket_id;
			if (formData.type == 2) {
				this.rule.pic[0].required = false;
			} else {
				this.rule.pic[0].required = true;
			}
			if (
				(formData.title && formData.title.length > 500) ||
				(formData.title_en && formData.title_en.length > 500)
			) {
				this.$message.error("标题过长，请输入500以内内容");
				return;
			} else if (formData.illustrate && formData.illustrate.length > 500) {
				this.$message.error("摘要过长，请输入500以内内容");
				return;
			} else if (formData.pic && formData.pic.length > 1000) {
				this.$message.error("图片链接过长，请输入1000以内内容");
				return;
			} else if (formData.examine_desc && formData.examine_desc.length > 255) {
				this.$message.error("审核理由过长，请输入255以内内容");
				return;
			} else if (formData.url && formData.url.length > 500) {
				this.$message.error("原文链接过长，请输入500以内内容");
				return;
			}
			if (this.content.length > 0) {
				this.formData.content = this.content;
			}
			if (this.content_en.length > 0) {
				this.formData.content_en = this.content_en;
			}
			this.formData.status = status;
			this.formData.admin_id = getUserId() ? getUserId() : 0;
			console.log(this.admin_id);

			this.$refs[formName].validate((valid) => {
				const that = this;
				if (valid) {
					that.formData.special_id = that.formData.special_id
						? that.formData.special_id[that.formData.special_id.length - 1]
						: that.formData.special_id;
					this.loading = true;
					addInformation(formData)
						.then((res) => {
							this.loading = false;
							if (res.code == 200) {
								that.$router.push({ path: "/message/messagelist" });
							} else {
							}
						})
						.catch((error) => {
							this.loading = false;
						});
				} else {
					that.$message.error("缺少必填参数，请根据提示填写！");
					return false;
				}
			});
		},
		// 获取专题数据
		getspecialList() {
			var childNodes = [];
			const that = this;
			specialList({})
				.then((res) => {
					if (res.code == 200) {
						that.specialList = that.toTree(res.data.list);
						if (that.tmp_special_id != undefined && that.tmp_special_id != "") {
							that.formData.special_id = this.getParentsById(
								that.specialList,
								that.tmp_special_id
							);
						}
					} else {
						that.$message.error(res.message);
					}
				})
				.catch((error) => {
					that.$message.error(error.message);
				});
		},
		// 获取资讯详情
		getInformationDetail() {
			const that = this;
			getInformationDetail({ id: that.$route.query.id })
				.then((res) => {
					if (res.code == 200) {
						for (let key in that.formData) {
							that.formData[key] = res.data[key];
						}

						that.getDefaultCate(that.formData.type);
						that.handlelanguageChange(that.formData.language);

						that.formData["id"] = res.data.id;
						that.formData.status = that.formData.status.toString();

						that.formData.prohibit_tran =
							that.formData.prohibit_tran.toString();

						that.formData.language = that.formData.language
							? that.formData.language.toString()
							: that.formData.language;

						that.formData.category_id = that.formData.category_id
							? that.formData.category_id.toString()
							: that.formData.category_id;

						that.formData.type = that.formData.type
							? that.formData.type.toString()
							: that.formData.type;

						that.formData.is_home = that.formData.is_home.toString();

						that.formData.is_recommend = that.formData.is_recommend.toString();

						that.formData.is_top = that.formData.is_top.toString();

						that.formData.allow_tgpush = that.formData.allow_tgpush.toString();

						that.ticketArray = that.getLstInteger(that.formData.ticket_id);
						that.formData.label_id = that.formData.label_id
							? that.formData.label_id.toString()
							: that.formData.label_id;
						that.tmp_special_id = that.formData.special_id;
						that.formData.special_id = this.getParentsById(
							this.specialList,
							that.formData.special_id
						);

						that.getUserType();
					} else {
						that.$message.error(res.message);
					}
				})
				.catch((error) => {
					that.$message.error(error.message);
				});
		},
		getParentsById(list, id) {
			console.log("getParentsById" + list);

			for (let i in list) {
				if (list[i]["value"] == id) {
					return [list[i]["value"]];
				}
				if (list[i].children) {
					let node = this.getParentsById(list[i].children, id);
					if (node !== undefined) {
						// 追加父节点
						node.unshift(list[i]["value"]);
						console.log("node" + node);

						return node;
					}
				}
			}
		},
		getLstInteger(str) {
			let lStrTemp = [];
			console.log("getLstInteger:", str);

			str.split(",").map((u) => {
				lStrTemp.push(parseInt(u));
			});
			lStrTemp.splice(lStrTemp.length - 1, 1);
			return lStrTemp;
		},
		back() {
			this.$confirm("取消编辑将丢失已经修改的数据，确认取消编辑吗？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$router.push({ path: "/message/messagelist" });
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消返回",
					});
				});
		},
		clearFormAdd() {
			for (let key in this.formData) {
				this.formData[key] = "";
			}
			this.formData.prohibit_tran = "0";
			this.formData.allow_tgpush = "1";
			this.formData.is_top = "0";
			this.formData.is_home = "0";
			this.formData.is_push = "0";
			this.formData.is_recommend = "0";
			this.formData.status = "0";
		},

		getUserType() {
			if (this.formData.author_id && this.userList.length > 0) {
				for (let index = 0; index < this.userList.length; index++) {
					const element = this.userList[index];
					if (element.id == this.formData.author_id) {
						this.userType = element.userType.toString();
					}
				}
			}
			this.getUserSelList(this.userType);
		},

		getUserSelList() {
			this.userSelList = [];
			var userType = Number(this.userType);
			for (let index = 0; index < this.userList.length; index++) {
				const element = this.userList[index];
				if (element.userType == userType) {
					this.userSelList.push(element);
				}
			}
		},
		handleUserTypeChange(e) {
			this.getUserSelList(this.userType);

			this.formData.author_id = "";
		},

		getDefaultCate(e) {
			if (e == 2) {
				this.rule.pic = [{ trigger: "change", required: false }];
			} else {
				this.rule.pic = [
					{ validator: validateNull, trigger: "change", required: true },
				];
			}

			if (this.categoryList.length > 0 && this.formData.category_id) {
				this.categoryList_show = [];

				for (let index = 0; index < this.categoryList.length; index++) {
					const element = this.categoryList[index];
					if (element.type == e) {
						this.categoryList_show.push(element);
					}
				}
			}
		},
		// 分类选择回调
		handletypeChange(e) {
			if (e == 2) {
				this.rule.pic = [{ trigger: "change", required: false }];
			} else {
				this.rule.pic = [
					{ validator: validateNull, trigger: "change", required: true },
				];
			}
			this.categoryList_show = [];

			for (let index = 0; index < this.categoryList.length; index++) {
				const element = this.categoryList[index];
				if (element.type == e) {
					if (this.categoryList_show.length == 0) {
						this.formData.category_id = element.id.toString();
					}
					this.categoryList_show.push(element);
				}
			}
		},
		handlelanguageChange(e) {
			if (e == 2) {
				this.rule.title = [{ trigger: "blur", required: false }];
				this.rule.illustrate = [{ trigger: "blur", required: false }];
				this.rule.content = [{ trigger: "blur", required: false }];

				this.rule.title_en = [
					{ validator: validateNull, trigger: "blur", required: true },
				];
				this.rule.illustrate_en = [
					{ validator: validateNull, trigger: "blur", required: true },
				];
				this.rule.content_en = [
					{ validator: validateNull, trigger: "blur", required: true },
				];
			} else {
				this.rule.title_en = [{ trigger: "blur", required: false }];
				this.rule.illustrate_en = [{ trigger: "blur", required: false }];
				this.rule.content_en = [{ trigger: "blur", required: false }];

				this.rule.title = [
					{ validator: validateNull, trigger: "blur", required: true },
				];
				this.rule.illustrate = [
					{ validator: validateNull, trigger: "blur", required: true },
				];
				this.rule.content = [
					{ validator: validateNull, trigger: "blur", required: true },
				];
			}
		},
		// 准备富文本编辑器
		onEditorReady(quill) {},
		//失去焦点事件
		onEditorBlur(quill) {
			// console.log("editor blur!", quill);
		},
		//获得焦点事件
		onEditorFocus(quill) {
			// console.log("editor focus!", quill);
		},
		//内容改变事件
		onEditorChange({ quill, html, text }) {
			// console.log("editor change!", html);
			let newStr = html.replace(
				/><br><\/p>/g,
				'><span style="font-size: 18px;"> </span></p>'
			);
			// console.log("new change!", newStr);
			if (this.formData.language == 2) {
				this.content_en = newStr;
			} else {
				this.content = newStr;
			}
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}
.upload_img_div {
	display: flex;
}
.upload_img_del {
	margin-left: -15px;
	margin-top: -15px;
}
.upload_img_del img {
	width: 30px;
	height: 30px;
}
.add_main {
	width: 100%;
	height: 81vh;
	overflow-y: scroll;
	margin: 0 auto;
}

.sub_content {
	margin-left: 15%;
	margin-right: 15%;
}
</style>
<style>
.ql-snow .ql-picker {
	line-height: 24px;
}
.ql-container {
	height: 400px;
	overflow: auto;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimHei"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimHei"]::before {
	content: "黑体";
	font-family: "SimHei";
}

.ql-snow
	.ql-picker.ql-font
	.ql-picker-label[data-value="Microsoft-YaHei"]::before,
.ql-snow
	.ql-picker.ql-font
	.ql-picker-item[data-value="Microsoft-YaHei"]::before {
	content: "微软雅黑";
	font-family: "Microsoft YaHei";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="KaiTi"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="KaiTi"]::before {
	content: "楷体";
	font-family: "KaiTi";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="FangSong"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="FangSong"]::before {
	content: "仿宋";
	font-family: "FangSong";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
	content: "Arial";
	font-family: "Arial";
}

.ql-snow
	.ql-picker.ql-font
	.ql-picker-label[data-value="Times-New-Roman"]::before,
.ql-snow
	.ql-picker.ql-font
	.ql-picker-item[data-value="Times-New-Roman"]::before {
	content: "Times New Roman";
	font-family: "Times New Roman";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
	content: "sans-serif";
	font-family: "sans-serif";
}

.ql-font-SimSun {
	font-family: "SimSun";
}

.ql-font-SimHei {
	font-family: "SimHei";
}

.ql-font-Microsoft-YaHei {
	font-family: "Microsoft YaHei";
}

.ql-font-KaiTi {
	font-family: "KaiTi";
}

.ql-font-FangSong {
	font-family: "FangSong";
}

.ql-font-Arial {
	font-family: "Arial";
}

.ql-font-Times-New-Roman {
	font-family: "Times New Roman";
}

.ql-font-sans-serif {
	font-family: "sans-serif";
}

/* 字号设置 */
/* 默认字号 */
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: "14字号";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
	content: "14字号" !important;
	font-size: 14px;
}

.ql-size-14px {
	font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
	content: "16字号";
	font-size: 16px;
}

.ql-size-16px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
	content: "18字号";
	font-size: 16px;
}

.ql-size-18px {
	font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
	content: "20字号";
	font-size: 16px;
}

.ql-size-20px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
	content: "22字号";
	font-size: 16px;
}

.ql-size-22px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
	content: "26字号";
	font-size: 16px;
}

.ql-size-26px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
	content: "28字号";
	font-size: 16px;
}

.ql-size-28px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
	content: "30字号";
	font-size: 16px;
}

.ql-size-30px {
	font-size: 16px;
}
</style>
<style>
.quill-editor {
	margin-left: -130px;
}
.ql-container {
	height: 800px;
}
.ql-editor ul li {
	font-size: 14px;
	margin-bottom: 20px;
	font-weight: 400;
	line-height: 21px;
}
.ql-editor ul li::before {
	font-size: 20px;
	vertical-align: middle;
	font-weight: 400;
}

.ql-editor p {
	text-align: justify;
	margin-top: 20px;
	font-size: 18px;
}

.ql-editor p img,
.ql-editor p span img {
	margin-top: 10px;
	margin-bottom: 10px;
}
.ql-editor p p,
.ql-editor p a,
.ql-editor p em,
.ql-editor blockquote span,
.ql-editor p blockquote span,
.ql-editor p span {
	font-size: 18px;
	font-weight: 400;
	line-height: 36px;
}

.ql-editor blockquote {
	padding-top: 24px;
	padding-bottom: 20px;
	background: #fafafa;
	font-family: PingFang SC;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 字段三级 */
	color: #b2b2b2;
}

.ql-editor h1,
.ql-editor h1 * {
	font-size: 26px !important;
	font-weight: bold !important;
	color: #0056ff !important;
	display: flex;
	background: #e7efff;
}
.ql-editor h1 {
	margin-top: 40px !important;
	margin-bottom: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 16px;
}

.ql-editor h2,
.ql-editor h2 * {
	font-size: 22px !important;
	font-weight: bold !important;
	color: #0056ff !important;
	display: flex;
	background: #e7efff;
}

.ql-editor h2 {
	margin-top: 30px !important;
	margin-bottom: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 16px;
}
.ql-editor h3,
.ql-editor h3 * {
	font-size: 20px !important;
	font-weight: bold !important;
	color: #0056ff !important;
	display: flex;
	background: #e7efff;
}
.ql-editor h3,
.ql-editor h3 * {
	margin-top: 25px !important;
	margin-bottom: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 16px;
}
.ql-toolbar {
	background: white;
	width: 960px;
	margin-left: 0px;
}
@media screen and (max-width: 1300px) {
	.ql-toolbar {
		margin-left: -130px;
	}
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
	border: 1px solid gray;
}
.card_title {
	height: 40px;
}
</style>
<style>
.el-card__body,
.el-main {
	padding-top: 0 !important;
}
</style>
<style scoped>
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	/* 样式可以根据需求自行调整 */
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
	border-top: 4px solid #fff;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
